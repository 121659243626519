.invoiceModuleRightAlign{
    float: right;
    margin-left: 1rem;
}

.invoiceModuleLeftAlign{
    float: left;
}

.invoiceModuleClear{
    clear: both;
}

.invoiceModuleInlineBlock{
    display: inline-block;
}

.invoiceModuleInfoBlock{
    display: inline-block;
    min-width: 25%;
    padding: 0.5rem;

    vertical-align: top;
}

.invoiceModuleInfoBlockHeading{
    margin-bottom: 0.5rem;
}

.invoiceModuleSpacer{
    height: 1rem;
}

.invoiceModuleSum{
    padding-left: 3rem;
}