.frontpageBackground{
    background-color: #E0F7FA;
    min-height: calc(100vh - 64px);
}

.frontPageCloudCircleWrapper{
    position: relative;
}

.frontPageTitle{
    min-height: 220px;
    padding-top: 12rem;
}

.frontPageTitle > h1 {
    text-align: center;
    width: 100%;
}


/* Cloud circle */
.frontpageCloudCircle{
    position: absolute;
    top: -3.8rem;
    left: calc(50% - 7.5rem);

    width: 15rem;
    height: 15rem;

    background-color: #3F51B5!important;
}

.frontPageListWrapper{
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 2rem;
}

.frontPageListWrapper > div > div > div{
    min-height: 2rem;
}


.frontPageCategory{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;

    background-color: #E8EAF6;
    border: 1px solid black;
    border-radius: 0.5rem;
    overflow: hidden;

    margin: 0 0 1rem 0;
}

.frontPageCategory > div{
    padding: 0.5rem;
}

.frontPageCategory > div:first-child{
    font-weight: bold;

    background-color: #7986CB;
}

.frontpage-g6{
    display: inline-block;
    width: 100%;
    max-width: 100%;
    vertical-align: top;
}

@media only screen and (min-width : 768px) {
    .frontpageCloudCircle{
        top: -3.8rem;
        left: -3.8rem;
    }

    .frontPageTitle{
        min-height: 220px;
        padding-left: 210px;
        padding-top: 0;

        text-align: left;
    }

    .frontPageListWrapper{
        width: calc(50% - 1px);
    }

    .frontpage-sector,
    .frontPageCategory{
        max-width: calc(33% - 0.67rem);
    }

    .frontpage-g6{
        max-width: calc(50% - 1px);
    }

    .frontpage-sector:nth-child(2){
        border-left: 2px solid lavender;
        border-right: 2px solid lavender;
    }

    .frontPageCategory:nth-child(2){
        margin: 0 1rem 1rem 1rem!important;
    }
}

.frontpageCloudCircle > div {
    display: inline-block;
    position: relative;

    margin: 50% 0 0 50%;

    -webkit-transform: translate(-72.5%, -50%);
    -moz-transform: translate(-72.5%, -50%);
    -ms-transform: translate(-72.5%, -50%);
    -o-transform: translate(-72.5%, -50%);
    transform: translate(-72.5%, -50%);

}

.frontpageLogoCircle{
    width: 145%;
    height: 145%;
}

.frontPageButtonWrapper{
    text-align: center;
}

.frontpage-sector{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    padding: 1rem;
}

.frontpage-sector-heading{
    margin: 0 0 2rem 0;
    text-align: center;
    color: rgba(0,0,0,0.6);
}

.frontpage-sector > h3{
    text-align: center;
    color: rgba(0,0,0,0.5);
}

.frontpage-sector > p{
    text-align: center;
    color: rgba(0,0,0,0.6);
}

.frontpage-example-arrow{
    position: relative;
}

.frontpage-example-arrow > img{
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 0;
    top: 0.8rem;
    opacity: 0.7;
}

.frontpage-signup-wrapper{
    padding: 4rem 2.5rem 2.5rem 2rem;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
}

.frontpage-signup-caption,
.frontpage-signup-wrapper > p {
    color: rgba(0,0,0,0.6);
    font-size: 0.8rem;
}

.frontpage-topMargin{
    margin-top: 2rem;
}

.frontpage-adline{
    font-style: italic;
}