.loginModuleWrapper{
    text-align: center;
}

.loginModuleWrapper > div {
    display: inline-block;
    width: 300px;
}

.loginModuleNewUserSwitch{
    text-decoration: underline;

    color: #9E9E9E;
}

.loginModuleNewUserSwitch:hover{
    color: #424242;
    cursor: pointer;
}

.loginModuleLoadingWrapper{
    display: inline-block;

    -webkit-transform: translateY(6px);
    -moz-transform: translateY(6px);
    -ms-transform: translateY(6px);
    -o-transform: translateY(6px);
    transform: translateY(6px);
}

.loginModuleLoginError{
    color: #F44336;
}

.loginModulePasswordResetInfo{
    color: #9E9E9E;
}

/*.loginModulePasswordResetInfo:last-child{*/
    /*padding-bottom: 19px;*/
/*}*/

.loginModuleTOS{
    color: #9E9E9E;
    padding: 0 1rem 19px 1rem;
}

.loginModuleTOS > a:active, a:hover, a:link, a:visited{
    color: #9E9E9E;
}