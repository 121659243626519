.accountPageButtonWrapper{
    padding: 1rem 0 0 0;
}

.accountPageButtonsModuleWrapper{
    margin: 1rem 1rem 0 1rem;
}

.accountPageLoadingBodyWrapper{
    text-align: center;
}

.accountPageLoadingBodyWrapperDone{
    text-align: center;
}

.accountPageClear{
    clear: both;
}

.accountPageLoadingDoneStatusIconSuccess{
    font-size: 2rem;
    padding: 1rem;
}

.accountPageLoadingDoneStatusIconFailure{
    color: #F44336;
    font-size: 2rem;
    padding: 1rem;
}

.accountPageHalfPageOnSmallScreens{
    display: inline-block;
    width: 100%;
}


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .accountPageHalfPageOnSmallScreens{
        display: inline-block;
        width: calc(50% - 1px);
    }
}


.accountPageAddTextFieldPadding > div, h3{
    margin-left: 1rem;
}


.accountPagePaperMargins{
    margin: 1rem 1rem 0 1rem;
    padding-top: 0.2rem;
}

.accountPageSavePaymentInfoWrapper{
    padding: 1rem 0 1rem 1rem;
}