.loadingWrapper{
    text-align: center;
    padding-top: 2rem;
}

.loadingInner{
    display: inline-block;
}

.signInError{
    color: #F44336;
}

.logoutWrapper{
    position: absolute;
    text-align: center;
    padding-bottom: 2rem;
    bottom: 0;
    left: 50%;

    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.logoutSpacer{
    width: 100%;
    height: 7rem;
}