.employeeList{
    list-style-type: none;
    padding-left: 0;
}

.employeeWrapper{
    margin: 1rem 0 1rem 0;
    position: relative;
}

.employeeTextField{
    margin: 0 1rem 0.5rem 1rem;
    display: inline-block;
}

.deletedOverlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(94,95,94,0.47);
}

.deletedOverlay > div{
    position: relative;
    left: 50%;
    top: 50%;
}

.deletedOverlay > div > div{
    color: #F44336;
    display: inline-block;

    -webkit-transform: translate(-50%, -50%) rotate(-20deg);
    -moz-transform: translate(-50%, -50%) rotate(-20deg);
    -ms-transform: translate(-50%, -50%) rotate(-20deg);
    -o-transform: translate(-50%, -50%) rotate(-20deg);
    transform: translate(-50%, -50%) rotate(-20deg);

    font-size: 3rem;
    border-radius: 1rem;
    border: 0.3rem dashed #F44336;
    padding: 0.5rem;
}