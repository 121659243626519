.checkout-form-row{
    padding: 0.35rem 0.35rem;
}

.checkout-form-submit{
    background-color: rgb(63, 81, 181)!important;
    color: white;
    margin-top: 1rem;
}

.StripeElement{
    background-color: rgba(0,0,0,0.08);
    padding: 0.5rem;
    border-radius: 4px;
}

.StripeElement--focus{
    background-color: rgba(0,0,0,0.05);
}

.card-info-input{
    background-color: rgba(0,0,0,0.08);
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    border: 0;
    outline: none;
}

.card-info-input:focus{
    background-color: rgba(0,0,0,0.05);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.arrow-down{
    display: none!important;
}

.checkout-form-error-message{
    color: #f44336;
    text-align: center;
    padding: 0.5rem;
}

.checkout-form-container{
    position: relative;
}

.checkout-form-loading-container{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.8);
    z-index: 1;
}

.checkout-form-loading{
    text-align: center;
}

.checkout-form-tos{
    text-align: center;
    padding-top: 0.3rem;
    width: 100%;
}