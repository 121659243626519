.navButtonWrapper{
    height: 100%;

    padding-top: 5px;
}

.subscriptionWarningPaper{
    margin: 0;
    padding: 1rem 2rem 1rem 2rem;
    cursor: pointer;
}

.navigationContainerLogo{
    max-height: 45px;
    width: 45px;
    height: 45px;
    margin: 0 0.4rem 0 0.4rem;
}

.navigationWhiteRipple>div>span>div{
    background-color: white!important;
}