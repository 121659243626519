.footerModuleContent{
    padding: 1rem 1rem 1rem 0;
}

.footerModuleInfoBlock{
    padding: 0 1rem 1rem 1rem;
    display: inline-block;
    line-height: 1.2rem;
    vertical-align: top;
    max-width: 20rem;
}

.footerModuleInfoBlock > span:first-child{
    font-weight: bold;
}

.footerModuleInfoBlock > a{
    color: white;
}

.footerModuleInfoBlockSpacer{
    height: 5px;
}