.stampFormPaper{
    margin: 1rem 0 1rem 0;
    padding: 0.4rem 0 0.4rem 0.4rem;
    display: inline-block;
    width: 100%;
}

@media only screen and (min-width : 992px) {
    .stampFormPaper{
        width: calc(50% - 1rem);
        margin: 1rem 0 1rem 1rem;
    }

    .stampFormPaper:nth-child(odd){
        margin: 1rem 1rem 1rem 0;
    }
}

.stampFormName{
    font-weight: bold;
}

.lastStampText{
    float: left;
    display: inline-block;
}

.stampButton{
    display: inline-block;
    float: right;
    padding: 0 1rem 0.6rem 0;
}

.clear{
    clear: both;
}

.lastActionIn{
    color: #4CAF50;
}

.lastActionOut{
    color: #F44336;
}