.timesheet-result-table > thead > tr > td,
.timesheet-result-table > tbody > tr > td{
    background-color: honeydew;
}

.timesheet-result-table > thead > tr > td:nth-child(even),
.timesheet-result-table > tbody > tr > td:nth-child(even){
    background-color: beige;
}

.timesheet-result-table > tbody > tr > td > div{
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.timesheet-result-table{
    margin-bottom: 1.25rem;
}

.timesheet-excel{
    margin-left: 1rem;
}