.hintBox{
    border: 1px solid darkgray;
    background-color: lightgray;
}

.viewDeletedToggleWrapper{
    max-width: 15rem;
    margin: 1rem 0 1rem 0;
    padding: 1rem;
    border: 1px solid lightgray;
    float: right;
}

.clear{
    clear: both;
}

.overview-tab{
    padding: 0 1.5rem 0 1.5rem;
}

.audit-page-venue-info{
    margin: 1rem 0;
}
.audit-page-venue-info > p{
    margin: 0;
}