.entryIn{
    color: #4CAF50;
}

.entryOut{
    color: #F44336;
}

.resultsTableWrapper{
    padding-top: 2rem;
}

