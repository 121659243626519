.datePickerWrapper{
    display: inline-block;
    padding-right: 1rem;
}

.buttonWrapperAudit{
    padding: 1rem 1rem 0 0;
    display: inline-block;
}

.audit-info-table > thead > tr > td:first-child,
.audit-info-table > tbody > tr > td:first-child{
    padding-right: 1rem;
}