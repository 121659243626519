.moduleWrapper{
    padding: 1.5rem 0 0.5rem 0;
}

.moduleCard{
    margin: -1.5rem -1.5rem 0 -1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    background-color: #ffdead6b;
}

.moduleCard > h3 {
    margin-left: 0;
    margin-top: 0;
}

.linkWrapper > div{
    display: inline-block;
    padding: 0.25rem 0.75rem 0.25rem 0;
    vertical-align: middle;
}

.linkBox{
    border: 1px solid black;
    border-radius: 0.3rem;
    margin: 0.5rem 1rem 1rem 1rem;
    display: inline-block;
    padding: 0.5rem 2rem 0.5rem 2rem;
    background-color: white;
    max-width: calc(100% - 6rem);
}

.linkBox > div{
    word-wrap: break-word;
    max-width: 100%;
}

